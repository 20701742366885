// Contextual backgrounds
@import "../spaces";

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }

  a#{$parent},
  button#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color) {
  #{$parent} {
    background: linear-gradient(87deg,
        $color 0,
        adjust-hue($color, 25%) 100%) !important;
  }
}

// Shape (svg) fill colors
@each $color,
$value in $theme-colors {
  .fill-#{$color} {
    fill: $value;
  }

  .stroke-#{$color} {
    stroke: $value;
  }
}




.inputFlex {
  width: 30%;

  >* {
    flex: 1;
    flex-shrink: 0;
  }
}


@mixin gutter-y($space, $important: false) {
  @if $important {
    > :first-child {
      margin-top: 0 !important;
    }

    >* {
      margin-top: $space / 2 !important;
      margin-bottom: $space / 2 !important;
    }

    > :last-child {
      margin-bottom: 0 !important;
    }
  }

  @else {
    > :first-child {
      margin-top: 0;
    }

    >* {
      margin-top: $space / 2;
      margin-bottom: $space / 2;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}

@mixin gutter-x($space, $important: false) {
  @if $important {
    > :first-child {
      margin-left: 0 !important;
    }

    >* {
      margin-left: $space / 2 !important;
      margin-right: $space / 2 !important;
    }

    > :last-child {
      margin-right: 0 !important;
    }
  }

  @else {
    > :first-child {
      margin-left: 0;
    }

    >* {
      margin-left: $space / 2;
      margin-right: $space / 2;
    }

    > :last-child {
      margin-right: 0;
    }
  }
}

@mixin gutter($sufix, $space, $important: false) {
  @if $important {
    .gutter-x-#{$sufix}-i {
      @include gutter-x($space, $important);
    }

    .gutter-y-#{$sufix}-i {
      @include gutter-y($space, $important);
    }
  }

  @else {
    .gutter-x-#{$sufix} {
      @include gutter-x($space, $important);
    }

    .gutter-y-#{$sufix} {
      @include gutter-y($space, $important);
    }
  }
}

// Gutters
@include gutter('2', $space-2);
@include gutter('4', $space-4);
@include gutter('5', $space-5);
@include gutter('5', $space-5, true);
@include gutter('8', $space-8);
@include gutter('10', $space-10);
@include gutter('12', $space-12);
@include gutter('16', $space-16);
@include gutter('20', $space-20);
@include gutter('24', $space-24);
@include gutter('40', $space-40);