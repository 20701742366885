.pagination-filter {
    display: flex;
    align-items: center;

    .select-container {
        position: relative;
        display: inline-block;

        .form-control {
            -webkit-appearance: none; // Remove a aparência padrão do select no Chrome e Safari
            -moz-appearance: none; // Remove a aparência padrão do select no Firefox
            appearance: none; // Remove a aparência padrão do select
            background: transparent;
            position: relative;
            z-index: 1;
            border: none;
            cursor: pointer;
            margin-right: 8px;
        }

        .select-arrow {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
            pointer-events: none; // Evita a interação com o ícone
            color: #1496EE;
        }
    }
}