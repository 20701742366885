/*******************
use profile section
******************/

.user-profile {
  position: relative;
  background-size: cover;
  padding: 0;

  .profile-img {
    width: 50px;
    margin-left: 30px;
    padding: 35px 0;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: "";
      width: 50px;
      height: 50px;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    position: relative;

    >.dropdown>.dropdown-toggle {
      color: $white !important;
      width: 100%;
      padding: 7px 30px;
      background: rgba(0, 0, 0, 0.5);
      display: block;
      border: none;
      text-align: left;

      &:after {
        position: absolute;
        right: 20px;
        top: 13px;
      }
    }
  }

  .dropdown-menu {
    left: 0px;
    right: 0px;
    width: 180px;
    margin: 0 auto;
  }
}

.profile-dd {
  position: relative;

  .user-dd {
    padding: 6px 30px;
    background: #014e84bf;
    width: 100%;

    &:focus,
    &:active {
      background-color: #014e84bf;
      box-shadow: none !important;
    }

    &.dropdown-toggle::after {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }

  .btn-group {
    width: 100%;
  }

  .profile-user-img {
    padding-left: 25px;
  }
}