.title-responsive{
    font-size: calc(5px + 0.6vw);
    font-weight: bold;
    color: #3A4249 !important;
}
.subtitle-responsive{
    font-size: calc(5px + 0.5vw);
    color: #3A4249;
}

.label-responsive{
    font-size: calc(5px + 0.6vw);
    font-weight: bold;
    color: #3A4249 !important;
}

.color-primary{
  color:  #0B57D0;
}
.color-secondary{
  color:  #E36C1C;
}
.color-dark{
  color:  #3A4249;
}

.color-grey{
    color: #6C757D
}

.font-respon-14{
    font-size: calc(5px + 0.5vw);
}


@media screen and (min-width: 1920px) {
    .title-responsive{
        font-size: 20px;
    }
    
    .subtitle-responsive{
        font-size: 16px;
    }

    .label-responsive{
        font-size: 14px;
    }
    
    .font-respon-14{
        font-size: 14px;
    }
    
    
}