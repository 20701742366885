// App

/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
  display: none;
}

.page-wrapper>.page-content {
  padding: 30px;
  min-height: calc(100vh - 116px);
  background-color: #E9E9E9 !important;
}

.breadcrumb {
  background-color: #fff !important;
}

.page-breadcrumb {
  padding: 30px 30px 0px;
}

/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/

.left-part {
  height: calc(100vh - 172px);
  width: $left-part-width;
  position: absolute;
  overflow: hidden;
  border-right: 1px solid $border-color;

  .filter-listing {
    height: calc(100vh - 242px);
    overflow: auto;
  }
}

.right-part {
  width: calc(100% - 200px);
  height: calc(100vh - 176px);
  margin-left: 260px;

  &.app-drawer-content {
    width: calc(100% - 260px);
    margin-left: 260px;
  }
}

.right-part.chat-list {
  height: calc(100vh - 168px);
}

.right-left-part {
  width: 300px;
  position: absolute;
  border-right: 1px solid $border-color;

  .listing-over {
    height: calc(100vh - 258px);
    overflow: auto;
  }

  .listing-email-over {
    height: calc(100vh - 294px);
    overflow: auto;
  }
}

.right-right-part {
  width: calc(100% - 300px);
  height: calc(100vh - 175px);
  overflow: auto;
  margin-left: 300px;
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }

  .show-left-part {
    right: auto;
    left: -41px;
  }

  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

/*******************
 Vertical layout
*******************/

#main-wrapper[data-layout="vertical"] {

  /*Vertical*/
  .topbar .top-navbar .navbar-header {
    //width: $sidebar-width-full;
    transition: 0.2s ease-in;
  }

  /*sidebar type*/
  /* &[data-sidebartype='full'] {
    .page-wrapper {
      margin-left: $sidebar-width-full;
    }
  } */
  &[data-sidebartype="iconbar"] {
    .page-wrapper {
      margin-left: $sidebar-width-iconbar;
    }

    .left-sidebar,
    .topbar .top-navbar .navbar-header,
    .sidebar-nav ul .sidebar-item {
      width: $sidebar-width-iconbar;
    }

    .sidebar-nav {

      .has-arrow::after {
        top: 30px;
      }

      ul .sidebar-item {
        width: 150px;

        .sidebar-link {
          padding: 8px;
          display: block;
          text-align: center;

          i {
            display: block;
            visibility: visible;
            width: auto;
          }
        }
      }
    }
  }

  .container-notification {
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-notification {
      position: absolute;
      display: flex;
      justify-content: center;
      top: -5px;
      right: -2px;
      width: 15px;
      height: 15px;
      z-index: 100;
      background-color: #EF4853;
      color: white;
      border-radius: 50%;
      font-size: 11px;
      font-weight: bold;
      align-items: center;
    }
  }

  &[data-sidebartype="overlay"] {
    .topbar .top-navbar .navbar-header {
      width: $sidebar-width-full;
    }

    .left-sidebar {
      left: -$sidebar-width-full;
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }

  /*Sidebar position*/
  &[data-sidebar-position="fixed"] {
    .topbar .top-navbar .navbar-header {
      position: fixed;
      z-index: 10;
    }

    .left-sidebar {
      position: fixed;
    }
  }

  /*Header position*/
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }

    .page-wrapper {
      padding-top: $topbar-height;
    }
  }

  /*Boxed Layout*/
  &[data-boxed-layout="boxed"] {
    max-width: $boxed-width;
    margin: 0 auto;
    position: relative;
    box-shadow: 1px 0 80px rgba(0, 0, 0, 0.2);
  }

  &[data-boxed-layout="boxed"][data-header-position="fixed"] {
    .topbar {
      max-width: $boxed-width;
    }
  }
}

/*******************
 Vertical responsive layout
*******************/

@include media-breakpoint-up(md) {
  #main-wrapper[data-layout="vertical"] {

    /*Vertical*/
    &[data-sidebartype="full"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-full;
      }
    }

    main-wrapper[data-layout="vertical"][data-sidebar-position="fixed"] {
      .topbar .top-navbar .navbar-collapse {
        &[data-sidebartype="full"],
        &[data-sidebartype="overlay"] {
          margin-left: $sidebar-width-full;
        }
    
        &[data-sidebartype="mini-sidebar"] {
          margin-left: $sidebar-width-mini;
        }
    
        &[data-sidebartype="iconbar"] {
          margin-left: $sidebar-width-iconbar;
        }
      }
    }

    /*Vertical with mini-sidebar*/
    &[data-sidebartype="mini-sidebar"] {
      .topbar .top-navbar .navbar-header {
        width: $sidebar-width-mini;

        .logo-text {
          display: none;
        }

        &.expand-logo {
          width: $sidebar-width-full;

          .logo-text {
            display: block;
          }
        }
      }

      .profile-dd {
        .profile-user-img {
          padding-left: 12px;

          img {
            width: 40px;
          }
        }

        .username-dd {
          display: none;
        }
      }

      .left-sidebar {
        &:hover {
          .sidebar-nav ul {
            padding: 15px;

            .sidebar-item {
              margin: 5px;

              .sidebar-link {
                justify-content: start;
              }

              ul {
                padding: 0;
                padding: 5px;
              }
            }
          }

          // profile dropdown
          .profile-dd {
            .profile-user-img {
              padding-left: 25px;

              img {
                width: 50px;
              }
            }

            .username-dd {
              display: block;
            }
          }
        }
      }

      .sidebar-nav {
        li {
          max-width: 32px !important;
          margin-left: 6px;

          &:hover {
            min-width: 100% !important;
            margin-left: 0px;
          }
        }

        &:hover {
          li {
            min-width: 100% !important;
          }
        }


        ul {
          .nav-small-cap {
            padding: 8px 16px;
          }

          flex-direction: column;

          &:hover {
            flex-direction: column;
          }

          li .sidebar-item .sidebar-link {
            padding: 8px;
            padding-left: 6px;
            justify-content: center;
          }
        }
      }
    }

    &[data-sidebartype="mini-sidebar"] {
      .page-wrapper {
        margin-left: $sidebar-width-mini;
      }

      .sidebar-nav {
        .hide-menu,
        .has-arrow:after {
          display: none;
        }

        .nav-small-cap {
          justify-content: center;
        }

        .user-profile {
          padding-bottom: 15px;
          margin-bottom: 7px;

          .profile-img {
            padding: 15px 0 0 0;
            margin: 0px 0 0 6px;

            &:before {
              top: 15px;
            }
          }
        }
      }

      .left-sidebar {
        width: $sidebar-width-mini;
        overflow: hidden;

        .sidebar-item {
          .sidebar-link {
            background-color: #f6f6f6;
          }
        }

        &:hover {
          width: $sidebar-width-full;

          .sidebar-item {
            .sidebar-link {
              background-color: #fff;
            }
          }

          .sidebar-nav {

            .hide-menu,
            .has-arrow:after {
              display: block;
            }

            .nav-small-cap {
              justify-content: flex-start;
            }

            .user-profile {
              padding-bottom: unset;
              width: unset;
              margin-bottom: unset;

              .profile-img {
                padding: 35px 0;
                margin: 0px 0 0 30px;

                &:before {
                  top: unset;
                }
              }
            }
          }

          .first-level {
            .sidebar-item .sidebar-link i {
              visibility: hidden;
            }
          }

          .second-level .sidebar-item {
            padding: 0 0 0 20px;
          }
        }

        .first-level {
          .sidebar-item .sidebar-link i {
            visibility: visible;
          }
        }

        .second-level .sidebar-item {
          padding: 0 0 0 0px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #main-wrapper {
    &[data-sidebartype="mini-sidebar"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }

    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }

    &[data-layout="vertical"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }

    /*Header position*/
    &[data-layout="vertical"][data-header-position="fixed"][data-sidebar-position="fixed"] {
      .topbar .top-navbar {
        .navbar-collapse {
          position: relative;
          top: $topbar-height;
        }
      }
    }
  }

  .left-part {
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    top: $topbar-height;
    height: calc(100vh - 66px);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    position: fixed;
    transition: 0.1s ease-in;

    &.show-panel {
      left: 0px;
    }
  }

  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;

    &.show-panel {
      right: 0px;
    }
  }

  .right-part {
    width: 100%;
    margin-left: 0px;
    height: 500px;
  }
}

@media (min-width: 1024px) {
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .page-wrapper {
    margin-left: 240px;
  }
}

.ReactTable {
  border-radius: 4px;
  border: none !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
}

.rt-table {
  border-radius: 4px;
  border: none !important;
}

.ReactTable .rt-thead .rt-tr {
  background-color: #CCEAFF !important;
  height: 48px !important;
  color: #000;
  font-weight: 700;
  font-size: 12px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: none !important;

}

.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-tbody .rt-tr-group {
  border: none !important;
}

.rt-resizable-header-content {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  padding: 5px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
}

.rt-tbody {
  overflow-x: hidden !important;
  color: #000;
  font-weight: 400;
  font-size: 12px;
}