.logo-image {
    max-width: 300px !important;
}

iframe {
    pointer-events: none;
}

.logo-header {
    max-width: 165px;
}

.icon-header {
    max-width: 30px;
}
.swal2-icon {
  border-color: #fff !important;
}
.bg-blue-list-apps {
    background-color: #338c96 !important;
    color: #fff !important;
    border-radius: 2px 2px 0 0;
}

.shadow-header {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25) !important;
}

/* ------------- BUTTON */

.btn-round-acoes {
    width: 30px !important;
    height: 30px !important;
    line-height: 0px;
    border-radius: 4px !important;
    transition: 1s;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.btn-round-primary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    font-weight: 500 !important;
    letter-spacing: 0.01rem !important;
    font-size: 18px !important;
    line-height: 25.6px !important;
    border-radius: 31px !important;
    padding: 9px 12px !important;
    background-color: #0b57d0 !important;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

#blue {
    background-color: #1e88e5;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0069d9;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #df6b00;
    border-color: #df6b00;
}

.btn-info {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-info:hover {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
}

.btn-warning {
    color: #fff !important;
    background-color: #ffca0b;
    border-color: #ffca0b;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-warning:hover {
    color: #fff;
    background-color: #ffca0b;
    border-color: #ffca0b;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffca0b;
    border-color: #ffca0b;
}

/* ------------- BG - BACKGROUND */
.bg-blue {
    background-color:#E36C1C !important;
    color: #fff !important;
}

.bg-orange {
    background-color: #ff8c22 !important;
}

.bg-cyan {
    background-color: #FEF2E1!important;
    color: #3A4249 !important;
}

/* ------------- TABLE */

.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: normal !important;
}

/* ------------- CARD */

.title-card-md {
    font-size: 14px !important;
}

body {
    font-size: 0.775rem;
}

.hover-link-state:hover {
    cursor: pointer;
}
.hover-link-state:hover #titleFilter {
    opacity: 0.75;
}

/* ----------- FONTS */

.bold {
    font-weight: bold;
}

/* ----------- BADGES */
.badge-green {
    color: #fff;
    background-color: #7fb81b;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #7fb81b;
}

/* ------------ Menu SSO */
.logo-sso {
    max-width: 150px;
}

.mailbox .drop-title:after {
    background: initial;
}
#loading_login {
    position: relative;
    margin-left: 170px;
    margin-bottom: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main-container {
    padding-top: 9ch;
}