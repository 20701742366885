.font-bold {
    font-weight: $font-weight-bold;
}

.font-semibold {
    font-weight: $font-weight-semibold;
}

.font-normal {
    font-weight: $font-weight-normal;
}

.font-light {
    font-weight: $font-weight-light;
}

.font-medium {
    font-weight: $font-weight-medium;
}

.font-weight-subtitle {
    font-weight: 600 !important;
    font-size: calc(10px + 0.5vw);
    color: #6c757d !important;
}

.textarea-control {
    resize: none;
    overflow: hidden;
    min-height: 24px;
    max-height: 20em;
    line-height: 1.5;
}

//
.fixed-size-text {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (min-width: 1920px) {
    .font-weight-subtitle {
        font-size: 20px;
    }
}
